<script>
import { status, contractType } from '@/assets/data/optionData.js';
import moment from 'moment';
import { ValidateUtil } from '@/plugins/app-util.js';
import { InformationCircleIcon } from '@heroicons/vue/outline';
import ElementsTooltipSingleton from '@/components/elements/ElementsTooltipSingleton.vue';
import AppPopupWithCenteredButtons from '@/components/AppPopupWithCenteredButtons.vue';
import ConfirmPopup from '@/components/ConfirmPopup.vue';

export default {
    components: {
        InformationCircleIcon,
        ElementsTooltipSingleton,
        AppPopupWithCenteredButtons,
        ConfirmPopup,
    },
    props: {
        selectedEmployeeSeq: String,
        employeeStatusHistoryList: Array,
    },
    emits: ['afterSave', 'getHistory'],
    data() {
        return {
            dateValue: null,
            activeEmployee: false,
            dateFormat: 'YYYY-MM-DDTHH:mm:ss.sssZ',
            employeeStatus: null,
            modalTitle: null,
            contractInfoEmployeeList: [],
            employeeStatusList: [],
            employeeStatusHistorySeq: null,
            currentDepartmentSeq: null,
            isCurrentHead: false,
            showHeadCheckPopup: false,
            showDeleteCheckModal: false,
            showEditPopup: false,
            showInfoPopup: false,
            // employeeStatusHistoryList: null,
            showNoti: false,
            showNotiErr: false,
            nowEdit: false,
            showEditBtn: false,
            showStatusEdit: false,
            showVacationView: false,
            showResignationView: false,
            showDeleteEmployeeCheckPopup: false,
            isDeleteEmployeeChecked: false,
            disableDeleteButton: true,
            workPlaceOptions: [],
            departmentOptions: [],
            jobTitleOptions: [],
            employmentTypeOptions: contractType,
            statusOptions: status,
            lastWorkingDate: null,
            period: {
                diffYear: null,
                diffMonth: null,
                diffDay: null,
            },
            beforeEditData: {},
            workSchedule: {
                name: null,
                startTime: null,
                endTime: null,
                workTime: { hours: null, minutes: null },
                workDay: null,
                shiftList: null,
            },
            request: {
                employeeSeq: this.selectedEmployeeSeq,
                workPlaceSeq: null,
                departmentSeq: null,
                jobTitleSeq: null,
                joinDate: null,
                employeeStatus: null,
                departmentManagerEmployeeSeq: null,
            },
            requestError: {
                departmentSeq: null,
                jobTitleSeq: null,
                workSchedule: null,
                joinDate: null,
                endDateOfProbationaryPeriod: null,
                employeeStatus: null,
            },
            requestErrorMSG: {
                departmentSeq: 'Please select Department.',
                jobTitleSeq: 'The JobTitle should be selected',
                workSchedule: 'The WorkSchedule should be selected',
                joinDate: 'JoinDate should be selected',
                endDateOfProbationaryPeriod: '',
                employeeStatus: 'Employee status should be selected',
            },
            isHeadOfOrganition: false,
            editItemsEmployeeStatus: {
                employeeSeq: null,
                employeeStatus: null,
                employeeStatusContent: null,
                employeeStatusStartDatetime: null,
                employeeStatusEndDatetime: null,
                employeeResignationDate: null,
            },
            popupItems: {
                employeeSeq: null,
                employeeStatus: null,
                employeeStatusContent: null,
                employeeStatusStartDatetime: null,
                employeeStatusEndDatetime: null,
                employeeResignationDate: null,
            },
        }; // return
    }, //data
    async mounted() {
        const self = this;

        try {
            const departmentUrl = self.$api('uri', 'get-department');
            const departmentQuery = JSON.stringify({ showRootNode: true });
            const departmentRes = await self.$axios.get(departmentUrl, { params: { json_query: departmentQuery } });
            self.departmentOptions = departmentRes.data.data.list.map((dep) => ({
                text: dep.departmentName,
                value: dep.departmentSeq,
            }));

            const jobTitleUrl = self.$api('uri', 'get-jobTitle');
            const jobTitleQuery = JSON.stringify({ limit: -1, offset: 0 });
            const jobTitleRes = await self.$axios.get(jobTitleUrl, { params: { json_query: jobTitleQuery } });
            self.jobTitleOptions = jobTitleRes.data.data.list.map((job) => ({
                text: job.jobTitleName,
                value: job.jobTitleSeq,
            }));

            const workPlaceUrl = self.$api('uri', 'get-work-place');
            const workPlaceRes = await self.$axios.get(workPlaceUrl);
            const workPlaceList = workPlaceRes.data.data.list;
            self.workPlaceOptions = workPlaceList.map((place) => ({
                text: place.workPlaceName,
                value: place.workPlaceSeq,
            }));

            // 모든 API 호출이 완료된 후에 함수 호출
            self.getEmployeeJobInfo(self.selectedEmployeeSeq);
            self.getEmployeeStatusHistoryData(self.selectedEmployeeSeq);
            self.getContractInfoEmployeeList(self.selectedEmployeeSeq);
            self.$emit('getHistory');
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    },
    methods: {
        // -----------------------------------------------------------------------------
        loadTooltip(ele, msgFn, options) {
            this.__tooltipComponent__hidden__.loadBasic(ele, msgFn, options);
        },
        loadTooltipElementReady(ele) {
            if (ele == null) return; // auto-reload, so element can be null sometime.
            this.__tooltipComponent__hidden__ = ele;
        },
        // -----------------------------------------------------------------------------
        changeDate(date) {
            const now = new Date();
            const formatter = new Intl.DateTimeFormat('fr-CA', { year: 'numeric', month: 'numeric', day: 'numeric' });
            const dateString = formatter.format(date).replace(/-/g, '');
            return dateString;
        },
        allClose() {
            const self = this;
            self.showEditPopup = false;
            self.showInfoPopup = false;
            self.showStatusEdit = false;
            self.showVacationView = false;
            self.showDeleteCheckModal = false;
            self.showDeleteEmployeeCheckPopup = false;
            self.showResignationView = false;
        },
        moment: moment,
        parseInt: parseInt,
        changeDateFormat(dateItem) {
            const changeDate = moment(dateItem).format('MM/DD/YYYY');
            return changeDate;
        },
        getContractInfoEmployeeList(userSeq) {
            const self = this;

            const url = self.$api('uri', `get-employee-contract-info-history-one`).replace('{employeeSeq}', encodeURIComponent(userSeq));
            self.$axios
                .get(url)
                .then((res) => {
                    self.contractInfoEmployeeList = res.data.data.list;

                    if (self.contractInfoEmployeeList.length != 0) {
                        self.dateValue = self.contractInfoEmployeeList[0].employeeContractInfoStartDatetime;
                    }
                })
                .catch((err) => {
                    console.error('err : ', err);
                });
        },
        isWithin30Days(date) {
            const today = new Date();
            const diff = today.getTime() - new Date(date).getTime(); // date를 new Date()로 변환하여 차이를 계산
            const diffInDays = diff / (1000 * 60 * 60 * 24); // 밀리초를 일(day) 단위로 변환
            return diffInDays <= 30;
        },
        //vacation 만 중복 체크 한다
        editSubmitRequestAfterConfirm(editItemsEmployeeStatus) {
            const self = this;
            const startDate = self.editItemsEmployeeStatus.employeeStatusStartDatetime;
            const endDate = self.editItemsEmployeeStatus.employeeStatusEndDatetime;
            const employeeSeq = self.selectedEmployeeSeq;

            const requestItem = { startDate, endDate, employeeSeq };
            // duplication check
            const url = self.$api('uri', 'post-check-period-duplication');
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    let dupList = res.data.data.list;

                    if (dupList == null || dupList.length == 0) {
                        self.editSaveEmployeeVacation(editItemsEmployeeStatus);
                    } else {
                        alert('Date already has vacation.');
                    }
                })
                .catch((err) => {
                    console.log('fail: ', err);
                });
        },
        editSaveEmployeeVacation(editItemsEmployeeStatus) {
            const self = this;

            //init
            let { employeeSeq, employeeStatus, employeeStatusHistorySeq, employeeStatusContent, employeeStatusStartDatetime, employeeStatusEndDatetime } = '';

            employeeSeq = editItemsEmployeeStatus.employeeSeq;
            employeeStatusHistorySeq = editItemsEmployeeStatus.employeeStatusHistorySeq;

            employeeStatus = 'ON_LEAVE';
            employeeStatusContent = ValidateUtil.checkIsEmptyChangeSpace(self.editItemsEmployeeStatus.employeeStatusContent);
            employeeStatusStartDatetime = self.editItemsEmployeeStatus.employeeStatusStartDatetime;
            employeeStatusEndDatetime = self.editItemsEmployeeStatus.employeeStatusEndDatetime;

            if (employeeStatusStartDatetime > employeeStatusEndDatetime) {
                alert('Date input is incorrect. Please enter a valid date');
                return;
            }

            var requestItem = {
                employeeSeq,
                employeeStatus,
                employeeStatusHistorySeq,
                employeeStatusContent,
                employeeStatusStartDatetime,
                employeeStatusEndDatetime,
            };

            if (self.showEditPopup) {
                self.showEditPopup = false;
            } else {
                self.showEditPopup = true;
            }
            self.showEditPopup = false;

            const url = self.$api('uri', 'put-employee-status-history');
            self.$axios
                .put(`${url}/${encodeURIComponent(self.employeeStatusHistorySeq)}`, requestItem)
                .then((res) => {
                    console.log(res);
                    self.getEmployeeJobInfo(self.selectedEmployeeSeq);
                    self.getEmployeeStatusHistoryData(self.selectedEmployeeSeq);
                    self.$emit('afterSave');
                    self.$emit('getHistory');
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        editSaveEmployeeResignation(editItemsEmployeeStatus) {
            const self = this;

            //init
            let { employeeSeq, employeeStatus, employeeStatusHistorySeq, employeeStatusContent, employeeResignationDate } = '';

            employeeSeq = editItemsEmployeeStatus.employeeSeq;
            employeeStatusHistorySeq = editItemsEmployeeStatus.employeeStatusHistorySeq;
            employeeStatus = 'RESIGNED';
            employeeStatusContent = ValidateUtil.checkIsEmptyChangeSpace(editItemsEmployeeStatus.employeeStatusContent);

            employeeResignationDate = editItemsEmployeeStatus.employeeResignationDate;

            var requestItem = {
                employeeSeq,
                employeeStatus,
                employeeStatusHistorySeq,
                employeeStatusContent,
                employeeResignationDate,
            };

            const url = self.$api('uri', 'put-employee-status-history');
            self.$axios
                .put(`${url}/${encodeURIComponent(self.employeeStatusHistorySeq)}`, requestItem)
                .then((res) => {
                    self.showEditPopup = false;
                    console.log(res);
                    self.getEmployeeJobInfo(self.selectedEmployeeSeq);
                    self.getEmployeeStatusHistoryData(self.selectedEmployeeSeq);
                    self.$emit('afterSave');
                    self.$emit('getHistory');
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        changeDateType(dateString) {
            if (dateString == null) {
                return null;
            } else {
                dateString = new Date(dateString);
                return dateString;
            }
        },
        clickDeleteButton(employeeStatusHistory) {
            if (employeeStatusHistory.isShow) {
                employeeStatusHistory.isShow = false;
            } else {
                employeeStatusHistory.isShow = true;
            }

            const self = this;
            self.showDeleteCheckModal = true;
            self.employeeStatusHistorySeq = employeeStatusHistory.employeeStatusHistorySeq;
        },
        //데이터 삭제
        deleteStatus() {
            const self = this;
            const employeeStatusHistorySeq = self.employeeStatusHistorySeq;
            // TODO:delete axios
            const url = self.$api('uri', 'delete-employee-status-history');

            var requestItem = { employeeStatusHistorySeq };

            self.$axios
                .delete(`${url}/${encodeURIComponent(employeeStatusHistorySeq)}`, requestItem)
                .then((res) => {
                    console.log(res);
                    self.showDeleteCheckModal = false;

                    self.getEmployeeJobInfo(self.selectedEmployeeSeq);
                    self.getEmployeeStatusHistoryData(self.selectedEmployeeSeq);
                    self.$emit('afterSave');
                    self.$emit('getHistory');
                })
                .catch(console.log);
        },
        //open Modal
        clickInfoButton(employeeStatusHistory) {
            const self = this;
            self.showInfoPopup = true;

            if (employeeStatusHistory.isShow) {
                employeeStatusHistory.isShow = false;
            } else {
                employeeStatusHistory.isShow = true;
            }

            if (employeeStatusHistory.employeeStatus == 'ON_LEAVE') {
                self.modalTitle = 'Inactive';
            } else if (employeeStatusHistory.employeeStatus == 'RESIGNED') {
                self.modalTitle = 'Resigned';
            }

            //init
            self.editItemsEmployeeStatus.contractInfoContent = '';
            self.editItemsEmployeeStatus.employmentType = '';
            self.editItemsEmployeeStatus.employeeContractInfoStartDatetime = '';
            self.editItemsEmployeeStatus.employeeContractInfoEndDatetime = '';
            self.editItemsEmployeeStatus.employeeResignationDate = '';

            self.beforeEditData = { ...employeeStatusHistory };
            self.editItemsEmployeeStatus = self.beforeEditData;
            self.employeeStatusHistorySeq = self.editItemsEmployeeStatus.employeeStatusHistorySeq;
        },
        //open Modal
        clickEditButton(employeeStatusHistory) {
            const self = this;
            self.showEditPopup = true;

            if (employeeStatusHistory.employeeStatus == 'RESIGNED') {
                self.modalTitle = 'Resigned';
            } else {
                self.modalTitle = 'Inactive';
            }

            if (employeeStatusHistory.isShow) {
                employeeStatusHistory.isShow = false;
            } else {
                employeeStatusHistory.isShow = true;
            }

            //init
            self.editItemsEmployeeStatus.contractInfoContent = '';
            self.editItemsEmployeeStatus.employmentType = '';
            self.editItemsEmployeeStatus.employeeContractInfoStartDatetime = '';
            self.editItemsEmployeeStatus.employeeContractInfoEndDatetime = '';
            self.editItemsEmployeeStatus.employeeResignationDate = '';

            self.beforeEditData = { ...employeeStatusHistory };
            self.editItemsEmployeeStatus = self.beforeEditData;
            self.employeeStatusHistorySeq = self.editItemsEmployeeStatus.employeeStatusHistorySeq;
        },
        getEmployeeStatusHistoryData(userSeq) {
            const self = this;
            //init
            self.employeeStatusList = {};
            const url = self.$api('uri', `get-employee-status-history`).replace('{employeeSeq}', encodeURIComponent(userSeq));
            self.$axios
                .get(url)
                .then((res) => {
                    self.employeeStatusList = res.data.data.list;
                    if (self.employeeStatusList != null) {
                        const dbStartDate = self.employeeStatusList.employeeStatusStartDatetime;
                        const dbEndDate = self.employeeStatusList.employeeStatusEndDatetime;
                        const dbResignationDate = self.employeeStatusList.employeeResignationDate;
                        const today = new Date();
                        const todayTime = today.getTime();

                        if (dbStartDate != null) {
                            //휴직 날짜 비교
                            const dbStartDateObj = self.changeDateType(dbStartDate);
                            const dbEndDateObj = self.changeDateType(dbEndDate);

                            if (dbStartDateObj.getTime() >= todayTime) {
                                if (dbEndDateObj.getTime() < todayTime) {
                                    self.employeeStatus = self.employeeStatusList.employeeStatus;
                                }
                            } else {
                                // 현재 날짜가 DB에서 가져온 날짜보다 작을 때 - 아직
                            }
                        } else if (dbResignationDate != null) {
                            // 퇴직 날짜 비교
                            const dbResignationDateObj = self.changeDateType(dbResignationDate);
                            if (dbResignationDateObj.getTime() >= todayTime) {
                                self.employeeStatus = self.employeeStatusList.employeeStatus;
                            } else {
                                // 현재 날짜가 DB에서 가져온 날짜보다 작을 때 - 아직
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        clickOptionButton(employeeStatusHistory) {
            if (employeeStatusHistory.isShow) {
                employeeStatusHistory.isShow = false;
            } else {
                employeeStatusHistory.isShow = true;
            }
        },
        resetScheudleData() {
            return {
                name: null,
                startTime: null,
                endTime: null,
                workTime: { hours: null, minutes: null },
                workDay: null,
                shiftList: null,
            };
        },
        isValidCheck() {
            const self = this;
            let isValid = true;

            if (ValidateUtil.checkIsEmpty(self.request.joinDate)) {
                self.requestError.joinDate = self.requestErrorMSG.joinDate;
                isValid = false;
            }

            if (ValidateUtil.checkIsEmpty(self.request.departmentSeq)) {
                self.requestError.departmentSeq = self.requestErrorMSG.departmentSeq;
                isValid = false;
            }
            return isValid;
        },
        errMsgReset() {
            const self = this;
            for (let errMsgKey in self.requestError) {
                self.requestError[errMsgKey] = '';
            }
        },
        getEmployeeJobInfo(userSeq) {
            const self = this;

            self.workSchedule = self.resetScheudleData();
            const url = self.$api('uri', `get-employee-job-info`).replace('{employeeSeq}', encodeURIComponent(userSeq));
            self.$axios
                .get(url)
                .then((res) => {
                    self.request = { ...res.data.data };

                    if (self.request.employeeStatus == 'ACTIVE') {
                        self.activeEmployee = true;
                    }

                    self.isHeadOfOrganition = false;
                    if (self.request.departmentManagerEmployeeSeq != null) {
                        if (userSeq == self.request.departmentManagerEmployeeSeq) {
                            self.isHeadOfOrganition = true;
                            self.isCurrentHead = true;
                        }
                    }

                    if (res.data.data.workScheduleSeq) {
                        self.getScheduleData(res.data.data.workSchedule);
                    }

                    if (res.data.data.resignInformation) {
                        self.lastWorkingDate = res.data.data.resignInformation.resignationDate;
                    }

                    if (res.data.data.joinDate) {
                        self.getPeriodFromJoined(res.data.data.joinDate);
                    }
                    self.showEditBtn = true;
                    self.currentDepartmentSeq = res.data.data.departmentSeq;
                })
                .catch((err) => (self.showEditBtn = false));
        },
        getScheduleData(schedule) {
            const self = this;

            self.workSchedule.name = schedule.workScheduleName;
            self.workSchedule.startTime = moment(schedule.workStartTime, 'HHmm').format('HH : mm A');
            self.workSchedule.endTime = moment(schedule.workEndTime, 'HHmm').format('HH : mm A');
            const workTime = moment(schedule.workTime, 'HHmm');
            self.workSchedule.workTime = { hours: workTime.format('HH'), minutes: workTime.format('mm') };
            self.workSchedule.workDay = schedule.workDay
                .split('')
                .map((i) => moment().weekday(i).format('ddd'))
                .join(', ');

            if (schedule.workScheduleType == 'SHIFT_TIME') {
                let shiftList = [];
                schedule.workScheduleShiftList.forEach((item) => {
                    let shift = {};
                    shift.startTime = moment(item.workStartTime, 'HHmm').format('HH : mm A');
                    shift.endTime = moment(item.workEndTime, 'HHmm').format('HH : mm A');

                    shiftList.push(shift);
                });

                self.workSchedule.shiftList = shiftList;
            }
        },
        cancelEditJobInfo() {
            const self = this;

            self.request = JSON.parse(JSON.stringify(self.beforeEditData));
            self.errMsgReset();
            self.$nextTick(() => {
                self.nowEdit = false;
            });
        },
        saveJobInfo() {
            const self = this;

            self.errMsgReset();
            if (!self.isValidCheck()) {
                self.showNotiErr = true;
                return;
            }

            let { departmentSeq, jobTitleSeq, joinDate, workPlaceSeq } = self.request;
            let isDepartmentManager = self.isHeadOfOrganition;
            const requestItem = { departmentSeq, jobTitleSeq, joinDate, workPlaceSeq, probationEndDate: null, isDepartmentManager: isDepartmentManager };

            const url = self.$api('uri', 'put-employee-job-info');
            self.$axios
                .put(`${url}/${encodeURIComponent(self.request.employeeSeq)}`, requestItem)
                .then((res) => {
                    // console.log(res.data);
                    self.showNoti = true;
                    self.nowEdit = false;
                    self.$emit('afterSave');
                    self.getEmployeeJobInfo(self.request.employeeSeq);
                    self.currentDepartmentSeq = requestItem.departmentSeq;
                    self.isCurrentHead = self.isDepartmentManager;
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        editButtonClick() {
            const self = this;

            self.beforeEditData = JSON.parse(JSON.stringify(self.request));
            self.nowEdit = true;
        },
        getPeriodFromJoined(joinDate) {
            if (!joinDate) {
                return;
            }

            const self = this;

            const originDate = moment(joinDate, 'YYYYMMDD');
            let currentDate = moment(self.lastWorkingDate, 'YYYYMMDD');
            let duration = moment.duration(currentDate.diff(originDate));

            let diffYear = duration.years();
            let diffMonth = duration.months();
            let diffDay = duration.days();

            self.period = { diffYear, diffMonth, diffDay };
        },
        addVacationSubmitRequestAfterConfirm() {
            const self = this;
            const startDate = self.popupItems.employeeStatusStartDatetime;
            const endDate = self.popupItems.employeeStatusEndDatetime;
            const employeeSeq = self.selectedEmployeeSeq;

            const requestItem = { startDate, endDate, employeeSeq };

            // duplication check
            const url = self.$api('uri', 'post-check-period-duplication');
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    let dupList = res.data.data.list;

                    if (dupList == null || dupList.length == 0) {
                        self.saveEmployeeVacation();
                    } else {
                        alert('Date already has vacation.');
                    }
                })
                .catch((err) => {
                    console.log('fail: ', err);
                });
        },
        saveEmployeeVacation() {
            const self = this;

            //init
            let { employeeSeq, employeeStatus, employeeStatusContent, employeeStatusStartDatetime, employeeStatusEndDatetime } = '';

            employeeSeq = ValidateUtil.checkIsEmptyChangeSpace(self.selectedEmployeeSeq);
            employeeStatus = 'ON_LEAVE';
            employeeStatusContent = ValidateUtil.checkIsEmptyChangeSpace(self.popupItems.employeeStatusContent);

            employeeStatusStartDatetime = self.popupItems.employeeStatusStartDatetime;
            employeeStatusEndDatetime = self.popupItems.employeeStatusEndDatetime;

            //  조건검사
            if (employeeStatusStartDatetime == null) {
                alert('Please enter a vaild start date');
                return;
            }

            if (employeeStatusEndDatetime == null) {
                alert('Please enter a vaild end date');
                return;
            }

            if (employeeStatusStartDatetime > employeeStatusEndDatetime) {
                alert('Date input is incorrect. Please enter a valid date');
                return;
            }

            if (ValidateUtil.checkIsEmpty(employeeStatusContent)) {
                alert('Notes can’t be blank');
                return;
            }

            var requestItem = {
                employeeSeq,
                employeeStatus,
                employeeStatusContent,
                employeeStatusStartDatetime,
                employeeStatusEndDatetime,
            };

            const url = self.$api('uri', 'post-employee-status-history');
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    self.allClose();
                    self.getEmployeeJobInfo(self.selectedEmployeeSeq);
                    self.$emit('getHistory');
                    self.$emit('afterSave');
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        resignedDuplicationCheck() {
            const self = this;
            const employeeSeq = self.selectedEmployeeSeq;

            const requestItem = { employeeSeq };
            // duplication check
            const url = self.$api('uri', 'post-check-resigned-duplication');
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    let dupList = res.data.data.list;

                    if (dupList == null || dupList.length == 0) {
                        self.saveEmployeeResignation();
                    } else {
                        alert('Already have Resigned information.');
                    }
                })
                .catch((err) => {
                    console.log('fail: ', err);
                });
        },
        saveEmployeeResignation() {
            const self = this;

            //init
            let { employeeSeq, employeeStatus, employeeStatusContent, employeeResignationDate } = '';

            employeeSeq = ValidateUtil.checkIsEmptyChangeSpace(self.selectedEmployeeSeq);
            employeeStatus = 'RESIGNED';
            employeeStatusContent = ValidateUtil.checkIsEmptyChangeSpace(self.popupItems.employeeStatusContent);

            employeeResignationDate = self.popupItems.employeeResignationDate;

            if (employeeResignationDate == null) {
                console.log('Please enter a Resigned date');
                alert('Please enter a vaild Resigned date');
                return;
            }

            if (ValidateUtil.checkIsEmpty(employeeStatusContent)) {
                alert('Notes can’t be blank');
                return;
            }

            var requestItem = {
                employeeSeq,
                employeeStatus,
                employeeStatusContent,
                employeeResignationDate,
            };

            const url = self.$api('uri', 'post-employee-status-history');
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    self.allClose();
                    self.getEmployeeJobInfo(self.selectedEmployeeSeq);
                    self.$emit('getHistory');
                    self.$emit('afterSave');
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        deleteAccount() {
            const self = this;
            self.editSaveEmployeeResigned();
            self.allClose();
        },
        editSaveEmployeeResigned() {
            const self = this;

            const employeeSeq = self.selectedEmployeeSeq;

            const url = self.$api('uri', 'put-employee-status-resignation').replace('{employeeSeq}', employeeSeq);
            self.$axios
                .put(url)
                .then((res) => {
                    self.allClose();
                    alert('The Resigned process has been completed.');
                    location.reload();
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        clickPermanentlyDeleteButton() {
            const self = this;
            self.showDeleteEmployeeCheckPopup = true;
        },
        clickStatusEdit() {
            const self = this;
            if (self.showStatusEdit) {
                self.showStatusEdit = false;
            } else {
                self.showStatusEdit = true;
            }
        },
        clickVacationButton() {
            const self = this;
            self.showVacationView = true;

            //init
            self.popupItems = {};
        },
        clickResignationButton() {
            const self = this;
            self.PopupProfileInfo = true;
            self.showResignationView = true;

            //init
            self.popupItems = {};
        },
        changeDisable() {
            this.disableDeleteButton = !this.isDeleteEmployeeChecked;
        },
        handlePopupClose() {
            if (!this.showDeleteEmployeeCheckPopup) {
                this.isDeleteEmployeeChecked = false;
                this.disableDeleteButton = true;
            }
        },
        resetHeadCheckbox() {
            if (this.currentDepartmentSeq === this.request.departmentSeq) {
                this.isHeadOfOrganition = this.isCurrentHead;
                return;
            }
            this.isHeadOfOrganition = false;
        },
        checkDuplicateHead(isChecked) {
            if (!isChecked) {
                return;
            }

            const self = this;
            const url = self.$api('uri', 'get-department-head').replace("{departmentSeq}", self.request.departmentSeq);
            self.$axios
                .get(url)
                .then((res) => {
                    let departmentHeadSeq = null;
                    if (res.data.data != null) {
                        departmentHeadSeq = res.data.data.departmentManagerEmployeeSeq;
                    }

                    if (departmentHeadSeq === null
                        || self.selectedEmployeeSeq === departmentHeadSeq) {
                            return;
                    }

                    // 이미 다른 head가 있을 경우 
                    self.isHeadOfOrganition = false;
                    self.showHeadCheckPopup = true;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<template>
    <div class="mr-3">
        <!-- info modal -->
        <ElementsTooltipSingleton
            :ref="
                (el) => {
                    loadTooltipElementReady(el);
                }
            " />
        <Teleport to="body">
            <AppPopup v-model="showInfoPopup" :name="modalTitle" :width800="true">
                <div v-if="editItemsEmployeeStatus.employeeStatus == 'ON_LEAVE'">
                    <div class="pr-7 pb-8 pl-7">
                        <!-- Period  -->
                        <div>
                            <div class="flex justify-between items-center gap-2 mt-6">
                                <div class="w-32 text-sm font-semibold text-slate-800">Period*</div>
                                <div class="text-sm text-slate-800">
                                    {{ changeDateFormat(editItemsEmployeeStatus.employeeStatusStartDatetime) }}
                                </div>
                                ~
                                <div class="text-sm text-slate-800">
                                    {{ changeDateFormat(editItemsEmployeeStatus.employeeStatusEndDatetime) }}
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Notes*</div>
                            <div class="text-sm text-slate-800">
                                {{ editItemsEmployeeStatus.employeeStatusContent }}
                            </div>
                        </div>
                    </div>
                    <!-- info button -->
                    <div class="flex w-full bg-white">
                        <div class="w-full p-4 border-t bg-white-200">
                            <div class="text-right justify-end flex">
                                <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click="showInfoPopup = false" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="editItemsEmployeeStatus.employeeStatus == 'RESIGNED'">
                    <div class="pr-7 pb-8 pl-7">
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Resigned Date*</div>
                            <div class="text-sm font-semibold text-slate-800">
                                {{ changeDateFormat(editItemsEmployeeStatus.employeeResignationDate) }}
                            </div>
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Notes*</div>
                            <div class="text-sm font-semibold text-slate-800">
                                {{ editItemsEmployeeStatus.employeeStatusContent }}
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full bg-white">
                        <div class="w-full p-4 border-t bg-white-200">
                            <div class="text-right justify-end flex">
                                <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click="showInfoPopup = false" />
                            </div>
                        </div>
                    </div>
                </div>
            </AppPopup>
        </Teleport>
        <!-- edit modal  -->
        <Teleport to="body">
            <AppPopup :width800="true" v-model="showEditPopup" :name="modalTitle">
                <div v-if="editItemsEmployeeStatus.employeeStatus == 'ON_LEAVE'">
                    <div class="pr-7 pb-8 pl-7" style="height: 400px">
                        <!-- Period  -->
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-30 text-sm font-semibold text-slate-800">Period*</div>
                            <ElementsDatePicker v-model="editItemsEmployeeStatus.employeeStatusStartDatetime" placeholder="Select Date" :textLeft="true" :format="dateFormat" :wfull="false" />
                            <span style="padding-left: 25px; padding-right: 25px"> ~ </span>
                            <ElementsDatePicker v-model="editItemsEmployeeStatus.employeeStatusEndDatetime" placeholder="Select Date" :textLeft="true" :format="dateFormat" :wfull="false" />
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-32 text-sm font-semibold text-slate-800">Notes*</div>
                            <ElementsInput v-model="editItemsEmployeeStatus.employeeStatusContent" class="flex-1" :maxlength="60" :full="true" />
                        </div>
                    </div>
                    <!-- vacation edit button -->
                    <div class="flex w-full bg-white">
                        <div class="w-full p-4 border-t bg-white-200">
                            <div class="text-right justify-end flex">
                                <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click="showEditPopup = false" />
                                <ElementsButton class="ml-2" text="Save" :width28="true" :height12="true" @click="editSubmitRequestAfterConfirm(editItemsEmployeeStatus)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="editItemsEmployeeStatus.employeeStatus == 'RESIGNED'">
                    <div class="pr-7 pb-8 pl-7">
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-40 text-sm font-semibold text-slate-800">Resigned Date*</div>
                            <ElementsDatePicker v-model="editItemsEmployeeStatus.employeeResignationDate" placeholder="Select Date" :textLeft="true" :format="dateFormat" class="w-full" />
                        </div>
                        <div class="flex justify-between items-center gap-2 mt-6">
                            <div class="w-40 text-sm font-semibold text-slate-800">Notes*</div>
                            <ElementsInput v-model="editItemsEmployeeStatus.employeeStatusContent" :maxlength="60" :full="true" class="w-full" />
                        </div>
                    </div>
                    <!-- 휴직 edit button -->
                    <div class="flex w-full bg-white">
                        <div class="w-full p-4 border-t bg-white-200">
                            <div class="text-right justify-end flex">
                                <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click="showEditPopup = false" />
                                <ElementsButton class="ml-2" text="Save" :width28="true" :height12="true" @click="editSaveEmployeeResignation(editItemsEmployeeStatus)" />
                            </div>
                        </div>
                    </div>
                </div>
            </AppPopup>
            <ElementsModal2Button
                v-model="showDeleteCheckModal"
                :modalTitle="'Delete Check'"
                :buttonText="'Confirm'"
                :modalContent="'Are you sure to want to delete this employee Status'"
                @afterClick="deleteStatus()" />
        </Teleport>
        <div class="flex justify-between items-center mt-6 h-10">
            <h4 class="text-lg font-bold">Job Information</h4>
            <button
                v-if="$appUtil.checkPermission('JOB_INFO_EDIT') && showEditBtn"
                v-show="!nowEdit"
                class="border rounded border-blue-500 w-24 h-10 bg-white text-sm font-semibold text-blue-500 hover:bg-blue-100 transition-all duration-500 text-center"
                @click="editButtonClick">
                Edit
            </button>
        </div>
        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Employment Type</div>
            <ElementsSelectRef
                v-model="request.employmentType"
                placeholder="Employment Type"
                class="flex-1"
                :readonly="true"
                :options="employmentTypeOptions"
                :full="true"
                :error="requestError.employmentType" />
        </div>
        <div class="flex items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Status</div>
            <div v-if="request.employeeStatus == 'ON_LEAVE'" style="background-color: #d0e1f2; color: #3f85ca" class="mt-1 rounded px-6 py-1.5 text-center text-sm font-bold w-fit">Inactive</div>
            <div v-else-if="request.employeeStatus == 'RESIGNED'" style="background-color: #d8d8d8; color: #666565" class="mt-1 rounded px-6 py-1.5 text-center text-sm font-bold w-fit">Resigned</div>
            <div v-else-if="request.employeeStatus == 'EXTENSION_REQUIRED'" class="font-semibold bg-yellow-50 text-sm mr-2 px-6 py-1.5 rounded" style="color: #f3d268">
                Extension <br />
                Required
            </div>
            <div v-else-if="request.employeeStatus == 'EXTENSION_PAST_DUE'" style="background-color: #f1b1bd; color: #f42053" class="mt-1 rounded px-6 py-1.5 text-center text-sm font-bold w-fit">
                Extension <br />
                Past Due
            </div>
            <div v-else-if="request.employeeStatus == 'CONTRACT_INFO_REQUIRED'" style="background-color: #f1b1bd; color: #f42053" class="mt-1 rounded px-6 py-1.5 text-center text-sm font-bold w-fit">
                Contract Info <br />
                Required
            </div>
            <div v-else class="mt-1 rounded bg-emerald-400/20 text-emerald-400 px-6 py-1.5 text-center text-sm font-bold w-fit">Active</div>
            <a @click="clickStatusEdit">
                <div>
                    <img src="@/assets/img/downArrow.svg" alt="downArrow" />
                </div>
            </a>
            <div v-if="showStatusEdit" class="border rounded border-gray-200 shadow-md shadow-gray-200 w-56 bg-white">
                <p @click="clickVacationButton" class="p-5 text-sm font-bold text-center transition-all duration-500s hover:bg-gray-100 border-t border-gray-200 cursor-pointer">Inactive</p>
                <p @click="clickResignationButton" class="p-5 text-sm font-bold text-center transition-all duration-500s hover:bg-gray-100 border-t border-gray-200 cursor-pointer">Resigned</p>
                <p
                    v-if="request.employeeStatus == 'RESIGNED'"
                    @click="clickPermanentlyDeleteButton"
                    class="p-5 text-sm font-bold text-center transition-all duration-500s hover:bg-gray-100 border-t border-gray-200 cursor-pointer">
                    Account Delete
                </p>
            </div>
        </div>
        <div class="border-b border-b-[#D6DDEB] px-7 py-5">
            <template v-for="(employeeStatusHistory, index) in employeeStatusHistoryList" :key="index">
                <div class="flex-1 ustify-between border border-[#D6DDEB] rounded-lg px-5 py-6 mb-4 relative">
                    <p v-if="employeeStatusHistory.employeeStatus == 'ON_LEAVE'" class="font-semibold truncate relative block items-center mb-1 text-sm text-gray-900">Inactive</p>
                    <p v-if="employeeStatusHistory.employeeStatus == 'RESIGNED'" class="font-semibold truncate relative block items-center mb-1 text-sm text-gray-900">Resigned</p>
                    <div class="text-right justify-end flex">
                        <img @click="clickOptionButton(employeeStatusHistory)" class="h-5 w-5 cursor-pointer rotate-90" src="@/assets/img/option.svg" alt="" />
                    </div>
                    <div v-if="employeeStatusHistory.employeeStatus == 'ON_LEAVE'">
                        <p class="text-base">
                            {{ moment(employeeStatusHistory.employeeStatusStartDatetime).format('MM/DD/YYYY') }} ~ {{ moment(employeeStatusHistory.employeeStatusEndDatetime).format('MM/DD/YYYY') }}
                        </p>
                    </div>
                    <div v-if="employeeStatusHistory.employeeStatus == 'RESIGNED'">
                        <p class="text-base">{{ moment(employeeStatusHistory.employeeResignationDate).format('MM/DD/YYYY') }}</p>
                    </div>
                    <transition
                        class="z-30"
                        enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95">
                        <div v-if="employeeStatusHistory.isShow" class="absolute top-12 right-4 border rounded border-gray-200 shadow-md shadow-gray-200 w-56 bg-white">
                            <p
                                @click="clickInfoButton(employeeStatusHistory)"
                                class="p-5 text-sm font-bold text-center transition-all duration-500s hover:bg-gray-100 border-t border-gray-200 cursor-pointer">
                                Info
                            </p>
                            <p
                                @click="clickEditButton(employeeStatusHistory)"
                                class="p-5 text-sm font-bold text-center transition-all duration-500s hover:bg-gray-100 border-t border-gray-200 cursor-pointer">
                                Edit
                            </p>
                            <p
                                @click="clickDeleteButton(employeeStatusHistory)"
                                class="p-5 text-sm font-bold text-center transition-all duration-500s hover:bg-gray-100 border-t border-gray-200 cursor-pointer">
                                Delete
                            </p>
                        </div>
                    </transition>
                </div>
            </template>
        </div>
        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Office</div>
            <ElementsSelectRef
                v-model="request.workPlaceSeq"
                placeholder="Select Office"
                class="flex-1"
                :readonly="!nowEdit"
                :options="workPlaceOptions"
                :full="true"
                :error="requestError.officeSeq"
                :useDefaultMsg="true" />
        </div>
        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="flex gap-2 w-32 text-sm font-semibold text-slate-800">
                <span>Department</span>
                <InformationCircleIcon
                    class="h-6 w-6 text-blue-600"
                    aria-hidden="true"
                    :ref="
                        (el) => {
                            loadTooltip(el, () => {
                                return 'Tick the head button if you want to set it as a header of this department. \
                        <br/>Only one header can be set in each department.';
                            });
                        }
                    " />
            </div>
            <ElementsSelectRef
                v-model="request.departmentSeq"
                placeholder="Select Department"
                class="flex-1 w-[20rem]"
                :readonly="!nowEdit"
                :options="departmentOptions"
                :full="true"
                :error="requestError.departmentSeq" 
                @change="resetHeadCheckbox"/>
            <ElementsCheckbox 
                v-model="isHeadOfOrganition" 
                :text="'Head'" 
                :disabled="!nowEdit" 
                @change="checkDuplicateHead"
            />
        </div>
        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Tier</div>
            <ElementsSelectRef
                v-model="request.jobTitleSeq"
                placeholder="Select Role"
                class="flex-1"
                :useDefaultMsg="true"
                :readonly="!nowEdit"
                :options="jobTitleOptions"
                :full="true"
                :error="requestError.jobTitleSeq" />
        </div>
        <div v-if="!$appUtil.checkSubscription('FLEXBEN_ONLY_USAGE')" class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Work Schedule</div>
            <template v-if="workSchedule.name">
                <div class="flex-1 flex justify-between gap-2">
                    <div class="border rounded-lg shadow shadow-gray-100 px-5 py-3.5">
                        <h4 class="text-sm font-semibold">{{ workSchedule.name }}</h4>
                        <div class="mt-1">
                            <div class="flex items-center gap-1 cursor-pointer">
                                <div class="text-xs font-medium text-slate-500 flex-1">
                                    {{ workSchedule.workDay }}
                                </div>
                            </div>
                        </div>
                        <template v-if="workSchedule.shiftList != null">
                            <template v-for="(shift, index) in workSchedule.shiftList" :key="index">
                                <p class="mt-1 text-xs font-medium">{{ shift.startTime }} - {{ shift.endTime }}</p>
                            </template>
                        </template>
                        <template v-else>
                            <p class="mt-1 text-xs font-medium">{{ workSchedule.startTime }} - {{ workSchedule.endTime }}</p>
                        </template>
                        <p class="mt-1 text-xs font-medium">{{ workSchedule.workTime.hours }} hours {{ workSchedule.workTime.minutes }} minutes in a day</p>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="flex-1 flex justify-between gap-2">
                    <div class="border rounded-lg shadow shadow-gray-100 px-5 py-3.5">
                        <div class="text-center">
                            <h3 class="mt-2 text-sm font-medium text-gray-900">No schedule</h3>
                            <p class="mt-1 text-sm text-gray-500">This employee is not applied in any schedule.</p>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="w-32 text-sm font-semibold text-slate-800">Join date</div>
            <div class="flex-1">
                <ElementsDatePicker v-model="request.joinDate" :disabled="!nowEdit" :full="true" :error="requestError.joinDate" @changeDate="getPeriodFromJoined" />
                <div class="mt-2 px-2 flex items-center gap-2" v-if="!nowEdit && request.joinDate">
                    <img src="@/assets/img/tab-job-info-1.svg" alt="tab-job-info-1" />
                    <p class="text-xs font-medium text-gray-400">{{ period.diffYear }} years {{ period.diffMonth }} months {{ period.diffDay }} days</p>
                </div>
                <div class="mt-2 px-2 flex items-start gap-2" v-show="nowEdit">
                    <img src="@/assets/img/tab-job-info-2.svg" alt="tab-job-info-2" />
                    <p class="text-xs font-medium text-gray-400">
                        This may affect employee's workday settings in Time and Leave.<br />Employee's upcoming leaves might have inaccurate duration as they<br />follow previous workday settings.
                    </p>
                </div>
            </div>
        </div>
        <div class="flex justify-between items-center gap-2 mt-6" v-if="request.employeeType == 'PROBATION'">
            <div class="w-32 text-sm font-semibold text-slate-800">End date of probationary period</div>
            <ElementsDatePicker
                v-model="request.endDateOfProbationaryPeriod"
                placeholder="MM/DD/YYYY"
                class="flex-1"
                :disabled="!nowEdit"
                :full="true"
                :error="requestError.endDateOfProbationaryPeriod" />
        </div>
        <div class="flex justify-between items-center gap-2 mt-6">
            <div class="flex justify-end items-center w-full bg-white">
                <ElementsButton v-show="nowEdit" text="Cancel" :width28="true" :height12="true" :background-white="true" @click="cancelEditJobInfo" />
                <ElementsButton v-show="nowEdit" class="ml-2" text="Save" :width28="true" :height12="true" @click="saveJobInfo" />
            </div>
        </div>
        <ElementsNotification v-model="showNoti" />
        <ElementsNotification v-model="showNotiErr" :notificationMsg="'Please check the error message'" :warning="true" />

        <Teleport to="body">
            <!-- add vacation -->
            <AppPopup v-model="showVacationView" name="Vacation" :width800="true">
                <div class="pr-10 pb-8 pl-7" style="height: 400px">
                    <!-- Period  -->
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-30 text-sm font-semibold text-slate-800">Period*</div>
                        <ElementsDatePicker v-model="popupItems.employeeStatusStartDatetime" placeholder="Select Date" :textLeft="true" :format="dateFormat" :wfull="false" />
                        <span style="padding-left: 25px; padding-right: 25px"> ~ </span>
                        <ElementsDatePicker v-model="popupItems.employeeStatusEndDatetime" placeholder="Select Date" :textLeft="true" :format="dateFormat" :wfull="false" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-32 text-sm font-semibold text-slate-800">Notes*</div>
                        <ElementsInput v-model="popupItems.employeeStatusContent" class="flex-1" :maxlength="60" :full="true" />
                    </div>
                </div>
                <!-- add button -->
                <div class="flex w-full bg-white">
                    <div class="w-full p-4 border-t bg-white-200">
                        <div class="text-right justify-end flex">
                            <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click="showVacationView = false" />
                            <ElementsButton class="ml-2" text="Save" :width28="true" :height12="true" @click="addVacationSubmitRequestAfterConfirm()" />
                        </div>
                    </div>
                </div>
            </AppPopup>
            <!-- add resignation -->
            <AppPopup v-model="showResignationView" name="Resigned" tooltip="If change status to resigned, this employee can’t log in.">
                <div class="pr-10 pb-8 pl-7">
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-40 text-sm font-semibold text-slate-800">Resigned Date*</div>
                        <ElementsDatePicker v-model="popupItems.employeeResignationDate" placeholder="Select Date" :textLeft="true" :format="dateFormat" class="w-full" />
                    </div>
                    <div class="flex justify-between items-center gap-2 mt-6">
                        <div class="w-40 text-sm font-semibold text-slate-800">Notes*</div>
                        <ElementsInput v-model="popupItems.employeeStatusContent" :maxlength="60" :full="true" class="w-full" />
                    </div>
                    <!--                    <div class="mt-6 text-right justify-end flex">-->
                    <!--                        <ElementsButton-->
                    <!--                            text="Permanently Delete the Account"-->
                    <!--                            :width72="true"-->
                    <!--                            :height12="true"-->
                    <!--                            :borderBlue="true"-->
                    <!--                            @click="clickPermanentlyDeleteButton"-->
                    <!--                        />-->
                    <!--                    </div>-->
                    <!--                    <p class="text-sm mt-6">-->
                    <!--                        <InformationCircleIcon class="h-5 w-5 ml-2 opacity-40" aria-hidden="true" style="display: inline"></InformationCircleIcon>-->
                    <!--                        If you click ‘Permanently Delete the Account’ button, all of information will be deleted in HRnFLEX.-->
                    <!--                    </p>-->
                </div>
                <div class="flex w-full bg-white">
                    <div class="w-full p-4 border-t bg-white-200">
                        <div class="text-right justify-end flex">
                            <ElementsButton text="Cancel" :width28="true" :height12="true" :background-white="true" @click="showResignationView = false" />
                            <ElementsButton class="ml-2" text="Submit" :width28="true" :height12="true" @click="resignedDuplicationCheck" />
                        </div>
                    </div>
                </div>
            </AppPopup>
            <AppPopupWithCenteredButtons
                v-model="showDeleteEmployeeCheckPopup"
                :backgroundWhite="disableDeleteButton"
                :disabled="disableDeleteButton"
                name="Delete Confirmation"
                button-text="Delete"
                @button-event="deleteAccount"
                @update:modelValue="handlePopupClose" 
            >
                <div class="mt-4 mb-6 text-center max-w-[644px] max-h-96 overflow-auto">
                    <span class="ml-3 pr-5">
                        Deleting this account will remove all information<br>
                        from the system and cannot be undone.<br>
                        Would you like to proceed?
                    </span>
                </div>
                <div class="text-sm mt-2 mb-4 flex items-center justify-start pl-32">
                    <input 
                        type="checkbox" 
                        id="deleteAccountCheckbox" 
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mr-3" 
                        v-model="isDeleteEmployeeChecked" 
                        @change="changeDisable" />
                    <label for="deleteAccountCheckbox" class="font-medium text-gray-500"> I understand and want to delete this account. </label>
                </div>
                <div v-if="disableDeleteButton" class="text-red-500 text-sm mt-4 mb-6 text-center font-bold">
                    Please tick the checkbox to proceed.
                </div>
            </AppPopupWithCenteredButtons>
            <ConfirmPopup
                v-model="showHeadCheckPopup"
                confirmDescription="This department already has a head assigned. Please remove the current Head to assign a new one."
                buttonText="OK"
                :isBold="false"
                :isTextLg="true"
            />
        </Teleport>
    </div>
</template>
